<template>
  <div class="main">
    <div class="alert_wrap">
      <el-alert
        v-for="(item, index) in errorMessages"
        :key="index"
        style="margin-bottom: 20px"
        :title="item"
        type="error"
        show-icon
      >
      </el-alert>
      <el-alert
        v-for="(item, index) in warningMessages"
        :key="index"
        style="margin-bottom: 20px"
        :title="item"
        type="warning"
        show-icon
      >
      </el-alert>
    </div>
    <div style="display: flex; align-items: center; flex-direction: column">
      <div class="subtitle">{{ $t("addLogin.WelcomeToJoin") }}</div>
      <div class="inputbox">
        <div slot="reference" class="i_emial">
          <input
            id="email"
            v-model="email"
            type="eamil"
            class="email all"
            autocomplete="off"
            disabled
            :placeholder="$t('addLogin.forgetPsd.place1')"
          />
          <i class="iconfont icon-email frontIcon"></i>
        </div>
        <div slot="reference" class="i_emial">
          <el-select
            v-model="currentArea"
            filterable
            @change="handleChangePhone"
            class="phone-selector"
            size="small"
          >
            <el-option
              v-for="(item, index) in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
              class="option-text"
            ></el-option>
          </el-select>
          <input
            v-model="phone"
            type="text"
            class="all inputX"
            :placeholder="$t('addLogin.loginr.phone')"
          />
        </div>
        <button v-show="false" id="getcodephoto"></button>
        <div class="input">
          <input
            id="code"
            v-model="phoneVerifyCode"
            :autoComplete="false"
            type="text"
            class="num all"
            :placeholder="
              $t('addLogin.register.VerificationCodeSentToCellPhone')
            "
          />
          <el-button
            type="primary"
            v-if="!isPhoneSend"
            class="yzm"
            @click="sendPhoneCode"
          >
            <p>{{ $t("addLogin.forgetPsd.getCode") }}</p>
          </el-button>
          <el-button type="primary" v-else class="yzm1">
            <p id="yzm2">{{ $t("addLogin.forgetPsd.message7") }}</p>
          </el-button>
        </div>
        <div class="i_code">
          <input
            id="password"
            v-model="password"
            type="password"
            class="email all"
            autocomplete="off"
            :placeholder="$t('addLogin.forgetPsd.PleaseSetPassword')"
          />
          <!-- 密码框右侧 图标 -->
          <i class="iconfont icon-locker frontIcon"></i>
          <i
            v-if="eye"
            class="iconfont icon-pwo backIcon"
            @click="changePwVisible"
          ></i>
          <i
            v-if="!eye"
            class="iconfont icon-pwc backIcon"
            @click="changePwVisible"
          ></i>
        </div>
        <div class="lobtn">
          <el-button
            class="login_goto"
            type="primary"
            :disabled="email.length === 0 || errorMessages.length !== 0"
            :loading="btnLoading"
            @click="handleLogin"
            >{{ $t("addLogin.forgetPsd.JoinNow") }}</el-button
          >
          <div class="agreement-box">
            <input
              type="checkbox"
              name="agreement"
              class="agreement-check"
              v-model="checked"
            />
            <label for="agreement">
              <div class="agreement-text">
                {{ $t("addLogin.register.agreement") }}&nbsp;
                <el-link
                  href="https://ms.ytdevops.com/pubKnowledge/85b934dc-d50c-4d73-9f48-9d89dd07c439"
                  Target="_blank "
                  type="primary"
                  >{{ $t("addLogin.register.privacyPolicy") }}</el-link
                >
                &nbsp;{{ $t("addLogin.register.and") }}&nbsp;
                <el-link
                  href="https://ms.ytdevops.com/pubKnowledge/8954eeec-f496-4f2d-84d3-ce62266d5ff7"
                  Target="_blank "
                  type="primary"
                  >{{ $t("addLogin.register.termsOfUse") }}</el-link
                >
              </div>
            </label>
          </div>
        </div>
      </div>
      <div id="space-semantic" style="position: absolute">
        <div id="embed-wrapper">
          <div class="embed-wrapper">
            <div id="captcha-element"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request_joinedProjects } from "@/network/personal/index.js";
import {
  getEmail,
  check,
  acceptInvitation,
  getV5Token,
  getV5Host,
  requestPhoneVerifyCode,
} from "@/network/login/index.js";
import { sessionSave } from "@/utils/session";
import { mapActions, mapMutations } from "vuex";
import Verify5 from "verify5-html";
import language from "@/components/language";
import { getWatermark } from "@/network/watermask";
import { metadata, PhoneNumberUtil } from "google-libphonenumber";
export default {
  components: {
    language,
  },
  data() {
    return {
      btnLoading: false,
      captcha: null,
      resultPhone: "",
      email: "",
      phone: "",
      password: "",
      phoneVerifyCode: "",
      issend: false,
      errorMessages: [],
      warningMessages: [],
      eye: false,
      isPhoneSend: false,
      phoneTimeStop: "",
      currentArea: this.$t("countryPhoneDefault"),
      options: this.$t("countryPhoneList"),
      countryCodePhone: "86",
      checked: false,
    };
  },
  computed: {
    userRedisUUID() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getGraphicverificationcode();
    getEmail(this.userRedisUUID)
      .then((email) => {
        check(this.userRedisUUID).then((res) => {
          this.email = email;
          this.errorMessages = res.errorMessages;
          this.warningMessages = res.warningMessages;
        });
      })
      .catch((err) => {
        this.email = "";
      });
  },
  methods: {
    ...mapMutations(["SET_WATERMASK"]),
    ...mapActions([
      "setTestPlan",
      "setVersion",
      "setSprint",
      "setBaseLine",
      "setUser",
      "setExecutionStatus",
      "setFileType",
      "setProjectUserList",
      "setStatus",
    ]),
    handleChangePhone(e) {
      const arr = e.split(" ");
      const list = arr[0].split("+");
      this.countryCodePhone = list[1];
      this.currentArea = arr[0];
    },
    set_release_obj(pid) {
      let i = pid ? pid : this.get_pid();
      this.setTestPlan(i);
      this.setVersion(i);
      this.setSprint(i);
      this.setBaseLine(i);
      this.setExecutionStatus(i);
      this.setFileType(i);
      this.setProjectUserList(i);
      this.setStatus(i);
    },
    check_email() {
      let emailRegExp = /^[\.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!emailRegExp.test(this.email)) {
        this.$message.warning(
          this.$t("addLogin.forgetPsd.PleaseEnterTheCorrectEmailDddress")
        );
        return false;
      }
      return true;
    },
    // 业务请求(带验证码校验)回调函数
    async captchaVerifyCallback(captchaVerifyParam) {
      const self = this;
      const phonereally = this.deletePhonezero();
      // 2.构造标准返回参数
      const params = {
        phone: phonereally,
        countryCode: self.countryCodePhone,
        verifyReqDto: {
          param: captchaVerifyParam,
        },
      };
      try {
        await requestPhoneVerifyCode(params).then((res) => {
          this.resultPhone = res.businessError;
        });
      } catch (error) {
        console.log(error);
      }
      if (this.resultPhone == null) {
        const verifyResult = {
          captchaResult: true, // 验证码验证是否通过，boolean类型，必选
          bizResult: true,
        };
        return verifyResult;
      } else if (this.resultPhone == "CAPTCHA_NOT_CORRECT") {
        const verifyResult = {
          captchaResult: false,
          bizResult: false,
        };
        return verifyResult;
      } else {
        const verifyResult = {
          captchaResult: true,
          bizResult: false,
        };
        return verifyResult;
      }
    },
    // 业务请求验证结果回调函数
    onBizResultCallback(bizResult) {
      const self = this;
      if (bizResult === true) {
        // 如果业务验证通过
        if (this.resultPhone == null) {
          self.$message({
            type: "success",
            message: self.$t("addLogin.register.VerificationCodeSent"),
            duration: "5000",
          });
          self.isPhoneSend = true;
          let timeo = 180;
          self.phoneTimeStop = setInterval(() => {
            timeo--;
            if (timeo > 0) {
              document.getElementById("yzm2").innerText =
                self.$t("addLogin.forgetPsd.message8") +
                timeo +
                self.$t("addLogin.forgetPsd.message9");
            } else {
              timeo = 180;
              self.isPhoneSend = false;
              clearInterval(self.phoneTimeStop);
            }
          }, 1000);
        }
      } else {
        // 如果业务验证不通过
        if (this.resultPhone == "USER_OPERATION_FREQUENCY_IS_TOO_FAST") {
          self.$message({
            type: "error",
            message: self.$t("addLogin.register.OperateFrequentPrompts"),
            duration: "5000",
          });
        }
      }
    },
    getGraphicverificationcode() {
      // 弹出式
      initAliyunCaptcha({
        SceneId: "bcuib0pi", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: "1vtf3d", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: "#captcha-element", //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: "#getcodephoto", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 360,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      });
    },
    // 绑定验证码实例函数。该函数为固定写法，无需修改
    getInstance(instance) {
      this.captcha = instance;
    },
    async sendPhoneCode() {
      const self = this;
      self.lock = true;
      if (self.phone.length < 1) {
        this.captcha.hide();
        self.$message.warning(
          self.$t("addLogin.register.PleaseEnterYourCellPhoneNumber")
        );
        self.lock = false;
        return false;
      }
      if (self.check_phone()) {
        this.captcha.show();
      } else {
        this.captcha.hide();
        self.lock = false;
        self.$message.warning(
          self.$t("addLogin.register.PleaseEnterTheCorrectCellPhoneNumber")
        );
      }
    },
    deletePhonezero() {
      const str = this.phone;
      if (str.charAt(0) === "0") {
        return str.substring(1);
      }
      return str;
    },
    handleLogin() {
      const phonereally = this.deletePhonezero();
      if (this.phone.length < 1) {
        this.$message.warning(
          this.$t("addLogin.register.PleaseEnterYourCellPhoneNumber")
        );
        return false;
      }
      if (this.phoneVerifyCode.length < 1) {
        this.$message.warning(this.$t("addLogin.forgetPsd.place3"));
        return false;
      }
      if (!this.check_email()) {
        return false;
      }
      if (this.password.length < 1) {
        this.$message.warning(this.$t("user.PleaseEnterYourPassword"));
        return false;
      }
      if (this.checked === false) {
        this.$message.warning(this.$t("addLogin.loginr.place3"));
        return false;
      }
      let ruleForm = {
        password: this.password,
        email: this.email,
      };
      const params = {
        userRedisUUID: this.userRedisUUID,
        data: {
          password: this.password,
          phoneVerifyCode: this.phoneVerifyCode,
          phone: this.countryCodePhone + phonereally,
        },
      };
      this.btnLoading = true;
      acceptInvitation(params)
        .then(() => {
          this.$store
            .dispatch("Login_password", ruleForm)
            .then((flag) => {
              if (flag === 1) {
                request_joinedProjects()
                  .then((res) => {
                    this.$store.commit("set_joined_projects", res);
                    this.set_release_obj(res[0].projectId);
                    sessionSave("p_id", res[0].projectId);
                    this.$router.push({ path: `/home` });
                  })
                  .catch(() => {
                    this.btnLoading = false;
                  });

                getWatermark()
                  .then((res) => {
                    this.SET_WATERMASK({
                      waterMaskSwitch: res.switchFlag ? true : false,
                      waterMaskValue: res.content ? res.content : "",
                    });
                  })
                  .catch(() => {
                    this.btnLoading = false;
                  });
              } else {
                this.btnLoading = false;
              }
            })
            .catch(() => {
              this.btnLoading = false;
            });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    check_phone() {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(
        this.phone,
        metadata.countryCodeToRegionCodeMap[this.countryCodePhone][0]
      );
      const isValite = phoneUtil.isValidNumber(number);
      return isValite;
    },
    changePwVisible() {
      if (document.getElementById("password").type == "password") {
        document.getElementById("password").type = "text";
        this.eye = true;
      } else {
        document.getElementById("password").type = "password";
        this.eye = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  width: 400px;
  font-size: 26px;
  font-weight: 700;
  text-align: start;
  color: rgba(56, 56, 56, 1);
}
.inputX {
  padding-left: 15px;
  padding-right: 40px;
  width: 480px;
  height: 50px;
  border: 1px solid rgba(195, 195, 195, 1);
  border-radius: 6px;
  outline: none;
}
.language-option {
  position: absolute;
  top: 20px;
  right: 20px;
}
.main {
  background: url();
}
.phone-selector {
  ::v-deep .el-input__inner {
    height: 50px !important;
    font-size: 16px;
  }
}
.input {
  margin-top: 30px;
  width: 400px;
  position: relative;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.num {
  font-size: 16px;
  padding-left: 15px;
  padding-right: 40px;
  width: 148px;
  height: 50px;
  border: 1px solid rgba(195, 195, 195, 1);
  border-radius: 6px;
  outline: none;
}
.tip {
  width: 370px;
  height: 60px;
  color: white;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; // 画布是10,topbar组件是99,关联弹窗遮>=2012
  transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.clear {
  color: inherit;
  user-select: none;
}

.main {
  width: 100%;
  display: grid;
  grid-template-columns: 100% 60%;
  // border: 2px solid white;
  border-radius: 20px;
  .alert_wrap {
    width: 60%;
    position: fixed;
    top: 3vh;
    left: 15vw;
    text-align: left;
    overflow-y: auto;
    z-index: 999;
    ::v-deep(.el-alert__content) {
      height: auto;
      padding: 10px;
    }
    ::v-deep .el-alert {
      width: 50%;
      margin: 0 auto;
    }
  }
  .inputbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;

    .lobtn {
      margin-top: 30px;

      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .login_goto {
        font-size: 16px;
        font-weight: 700;
        word-spacing: 10px;
        border-radius: 10px;
        width: 100%;
        margin: 0px 10px;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .router {
        height: 100%;
        text-align: start;
        font-size: 14px;
        display: flex;
        margin-top: 30px;
        align-self: start;
        span {
          color: rgba(137, 138, 140, 1);
        }
        p {
          margin: 0;
          color: rgba(47, 100, 181, 1);
        }
      }

      .agreement-box {
        margin-top: 30px;
        display: flex;
        width: 100%;
        .agreement-check {
          width: 14px;
          height: 14px;
        }
        .agreement-text {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          color: black;
          text-align: left;
          ::v-deep(a) {
            font-size: 14px;
            color: rgba(48, 100, 143, 1);
          }
          ::v-deep(a:hover) {
            font-size: 14px;
            color: #409eff;
          }
        }
        ::v-deep(.el-checkbox__input) {
          margin-top: 3px;
        }
      }
    }

    .yzm {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 50px;
      width: 239px;
      border: 1px solid rgba(48, 100, 143, 1);
      p {
        font-size: 16px;
        margin: 0;
      }
    }

    .yzm1 {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 50px;
      width: 239px;
      border: 1px solid rgba(48, 100, 143, 1);

      //   padding-left: 10px;
      p {
        letter-spacing: 2px;
        font-size: 16px;
        margin: 0;
        margin-left: 2px;
      }
    }

    .i_emial {
      margin-top: 30px;

      // margin-top: -50px;
      // margin-bottom: 100px;
      width: 400px;
      position: relative;
      padding-bottom: 5px;
      display: flex;
      justify-content: space-between;
    }
    .i_code {
      margin-top: 30px;

      position: relative;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
    }
    .forget {
      width: 400px;
      display: flex;
      justify-content: flex-end;
      // margin-bottom: 100px;
      margin-right: 20px;
      word-spacing: 5px;
      font-size: 14px;
      margin-top: 10px;
    }
    .email {
      width: 350px;
    }
    .email {
      padding-left: 40px;
      padding-right: 40px;
      width: 400px;
      height: 50px;
      border: 1px solid rgba(195, 195, 195, 1);
      border-radius: 6px;
      outline: none;
    }
    .all {
      font-size: 16px;
    }
    input::-webkit-input-placeholder {
      padding-left: 15px;
      font-size: 16px;
      color: rgba(195, 195, 195, 1);
      letter-spacing: 2px;
    }
  }
  .frontIcon {
    position: absolute;
    cursor: pointer;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 25px auto;
    margin-left: 11px;
    transform: translateY(-8px);
  }
  .backIcon {
    position: absolute;
    cursor: pointer;
    right: 22px;
    color: rgba(195, 195, 195, 1);
    font-size: 16px;
    margin: 25px auto;
    transform: translateY(-8px);
  }
}
// .r2{
//     cursor: not-allowed !important;
//   pointer-events:none !important;
// }
</style>
